// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import { FC, PropsWithChildren } from 'react';
import { AppContextProvider } from '~/containers/common/AppContext/AppContextProvider';
import {
  LoginEnforcer,
  LoginEnforcerProps,
} from '~/containers/common/LoginEnforcer';

export interface AuthContextProps
  extends PropsWithChildren,
    LoginEnforcerProps {}

export const AuthContext: FC<AuthContextProps> = ({ cookie, children }) => {
  return (
    <LoginEnforcer cookie={cookie}>
      <AppContextProvider>{children}</AppContextProvider>
    </LoginEnforcer>
  );
};
