// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { useDisclosure } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { ActionType } from '~/common/utilities/usage-event/actionType';
import { useDisclosureWithTracking } from '~/hooks/useDisclosureWithTracking';
import { AppContext } from './AppContext';

export const AppContextProvider: FC<PropsWithChildren> = ({ ...props }) => {
  const searchModal = useDisclosureWithTracking({
    openAction: ActionType.OPEN_SEARCH,
    closeAction: ActionType.CLOSE_SEARCH,
  });
  const mainMenuDrawer = useDisclosureWithTracking({
    openAction: ActionType.OPENED_MAIN_NAVIGATION_MENU,
    closeAction: ActionType.CLOSED_MAIN_NAVIGATION_MENU,
  });
  const moreDrawer = useDisclosure();

  return (
    <AppContext.Provider
      value={{
        searchModal,
        mainMenuDrawer,
        moreDrawer,
      }}
      {...props}
    />
  );
};
