// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { FlexProps, forwardRef } from '@chakra-ui/react';
import { HeaderBase } from '~/containers/layouts/HeaderBase';
import { HeaderContent } from './HeaderContent';

export const Header = forwardRef<FlexProps, 'div'>((props, ref) => {
  return (
    <HeaderBase ref={ref} {...props}>
      <HeaderContent />
    </HeaderBase>
  );
});
