// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { CollectionItem } from '~/common/gql';
import { MeQueryResult, useMeQuery } from '~/common/gql/user.generated';

export const useUserQueryHelper = () => {
  const meQuery = useMeQuery();
  const user: MeQueryResult['data']['self'] | undefined = meQuery.data?.self;
  const userSettings: MeQueryResult['data']['userSettings'] =
    meQuery.data?.userSettings ?? [];
  const isDefaultCollectionsEnabled = user?.defaultCollectionsEnabled ?? false;
  const isTodoDefault = userSettings?.some((i) => i.settingType == 'todo');

  // only reveals selected default collections if it should be applied to new items
  const defaultCollections: Pick<CollectionItem, 'collectionId'>[] =
    isDefaultCollectionsEnabled
      ? userSettings
          .filter((i) => i.settingType == 'collection')
          .map((i) => ({
            collectionId: i.collectionId,
          }))
      : [];

  return {
    user,
    userSettings: {
      isTodoDefault,
      defaultCollections,
    },
    ...meQuery,
  };
};
