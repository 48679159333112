// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { useApolloClient } from '@apollo/client';
import { useColorMode } from '@chakra-ui/react';
import _throttle from 'lodash/throttle';
import {
  COOKIE_COLOR_MODE_TOKEN,
  COOKIE_SESSION_TOKEN,
  COOKIE_SESSION_TOKEN_READABLE,
} from '~/common/cookieKeys';
import { useLogoutMutation } from '~/common/gql/user.generated';
import { localUserSessionData } from '~/common/utilities/local-store/localUserSessionData';
import { getCookies } from '~/lib/cookies';
import { datadog } from '~/lib/datadog/datadog-logger';

const throttle = _throttle(
  (fn) => {
    fn();
  },
  3000, // 3 seconds
  { leading: true, trailing: false },
);

export const useLogout = () => {
  const client = useApolloClient();
  const { setColorMode } = useColorMode();
  const [logoutMutation] = useLogoutMutation();

  const clearClientSessionData = () =>
    throttle(() => {
      try {
        // removes all gql data
        client.clearStore();

        // removes user data
        localUserSessionData.clear();

        // reset color mode
        setColorMode(''); // removes color mode setting immediately
        getCookies.fromBrowser().remove(COOKIE_COLOR_MODE_TOKEN, { path: '/' });

        // remove auth cookies
        getCookies.fromBrowser().remove(COOKIE_SESSION_TOKEN, { path: '/' });
        getCookies
          .fromBrowser()
          .remove(COOKIE_SESSION_TOKEN_READABLE, { path: '/' });
      } catch (e) {
        const error = e instanceof Error ? e : new Error(e as string);
        const log = datadog.generateLog({
          type: '[Monitoring]',
          message: 'Error clearing client session data.',
          error,
        });
        datadog.logger.then((logger) => logger.warn(...log));
      }
    });

  const logout = async () => {
    try {
      // invalidate server session
      await logoutMutation();
    } catch (e) {
      const error = e instanceof Error ? e : new Error(e as string);
      const log = datadog.generateLog({
        type: '[Monitoring]',
        message: 'Error logging out.',
        error,
      });
      datadog.logger.then((logger) => logger.warn(...log));
    }

    await clearClientSessionData();

    // redirect to login
    window.location.replace('/login');
  };

  return { logout, clearClientSessionData };
};
