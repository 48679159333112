// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import { ApolloError } from '@apollo/client/errors';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { FC, PropsWithChildren, useEffect } from 'react';
import { COOKIE_SESSION_TOKEN } from '~/common/cookieKeys';
import { LoginPage } from '~/containers/pages/FormPage/LoginPage';
import { useLogout } from '~/hooks/useLogout';
import { useUserQueryHelper } from '~/hooks/useUserQueryHelper';
import { getCookies } from '~/lib/cookies';

export interface LoginEnforcerProps extends PropsWithChildren {
  cookie: string;
}

const getIsNotAuthenticatedError = (error: ApolloError) => {
  const notAuthenticatedErrors = error?.graphQLErrors?.filter(
    (it) => it.message === 'Not authenticated',
  );
  const isNotAuthenticatedError = !isEmpty(notAuthenticatedErrors);
  return isNotAuthenticatedError;
};
/**
 * LoginEnforcer
 * Renders the login page if the user is not logged in, otherwise renders children.
 */
export const LoginEnforcer: FC<LoginEnforcerProps> = ({ cookie, children }) => {
  const { called, loading, error, refetch } = useUserQueryHelper();
  const isNotAuthenticatedError = getIsNotAuthenticatedError(error);
  const authCookie = getCookies
    .fromCookieString(cookie)
    .get(COOKIE_SESSION_TOKEN);
  const isAuthCookieSet = !isNil(authCookie);
  const { clearClientSessionData } = useLogout();

  useEffect(() => {
    if (!isAuthCookieSet) return;
    // check if cookie is valid by refetching the user query
    refetch().then((result) => {
      const isCookieInvalid = getIsNotAuthenticatedError(result.error);
      if (
        // if session is invalid and auth cookie exists
        // clear all client session data
        isCookieInvalid &&
        isAuthCookieSet
      ) {
        clearClientSessionData();
      }
    });
  }, [clearClientSessionData, isAuthCookieSet, refetch]);

  if (!isAuthCookieSet) {
    // show the login page if there is no auth cookie
    return <LoginPage />;
  }

  if (called && !loading && isNotAuthenticatedError) {
    // show the login page if the user is not authenticated
    return <LoginPage />;
  }

  return <>{children}</>;
};
