// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { ServerError } from '@apollo/client';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useLoginLazyQuery } from '~/common/gql/user.generated';
import { TranslationProtection } from '~/components/TranslationProtection/TranslationProtection';
import { StaticLayout } from '~/containers/layouts/StaticLayout';

export const LoginPage: FC = () => {
  const router = useRouter();
  const isLoginOnly =
    router.query.loginOnly && String(router.query.loginOnly) === 'true';

  const { register, handleSubmit, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    isValid,
    isSubmitted,
    touchedFields: { email: emailIsTouched, password: passwordIsTouched },
    errors,
  } = formState;

  const { email: emailError, password: passwordError } = errors;

  const isAllTouched = emailIsTouched && passwordIsTouched;

  const [login, result] = useLoginLazyQuery({
    nextFetchPolicy: 'network-only',
    onCompleted: async () => {
      if (location.pathname.startsWith('/login')) {
        router.replace('/app');

        return;
      }

      router.reload();
    },
  });

  const isGraphQLError = result.error && isEmpty(result.error?.networkError);
  const statusCode = (result.error?.networkError as ServerError)?.statusCode;
  // assumes a server error if status code is provided, unsure whether this is a correct assumption
  const isServerError = result.error && !isNil(statusCode);

  const onSubmit = ({ email, password }) => {
    login({
      variables: {
        input: {
          email,
          password,
        },
      },
    });
  };

  return (
    <StaticLayout>
      <Stack
        pt={8}
        as="form"
        w={['xs', 'sm']}
        spacing={3}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl id="fc-email" isInvalid={!!emailError}>
          <FormLabel>Email</FormLabel>
          <Input
            id="email"
            type="email"
            aria-label="email"
            name="email"
            autoComplete="email"
            autoFocus
            isRequired
            isDisabled={result.loading}
            {...register('email', {
              required: true,
              pattern: /\S+@\S+/,
            })}
          />
        </FormControl>
        <FormControl
          id="fc-password"
          isInvalid={isAllTouched && !!passwordError}
        >
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            aria-label="password"
            name="password"
            autoComplete="current-password"
            isRequired
            isDisabled={result.loading}
            {...register('password', { required: true })}
          />
        </FormControl>
        <Box minH={'32px' /* Reduces layout shift */}>
          <FormControl isInvalid={isSubmitted && !isValid}>
            <FormErrorMessage>
              Please enter a valid email and password.
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!result.error}>
            <FormErrorMessage>
              {isGraphQLError
                ? result.error.message
                : `There is an issue with the ${
                    isServerError ? 'server' : 'network'
                  }.`}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Button variant="npPrimary" type="submit">
          <TranslationProtection>Log in</TranslationProtection>
        </Button>

        {!isLoginOnly && (
          <NextLink
            passHref
            href={{
              pathname: '/forgot-password',
              query: { email: getValues('email') },
            }}
            legacyBehavior
          >
            <Link variant="npAccent" textAlign="center">
              I forgot my password
            </Link>
          </NextLink>
        )}
        {!isLoginOnly && (
          <Text textAlign="center">
            Don’t have an account yet?{' '}
            <Link variant="npAccent" href="/signup">
              Sign up
            </Link>
          </Text>
        )}
      </Stack>
    </StaticLayout>
  );
};
