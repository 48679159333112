// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import {
  Box,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Link,
  LinkProps,
  Stack,
} from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { ReactIcon } from '~/components/Icons';
import { Footer } from './marketing';

interface ReturnToLinkProps extends LinkProps {
  ariaLabel: string;
}

interface Props extends PropsWithChildren {
  backToPageProps?: ReturnToLinkProps;
  hasTagline?: boolean;
  split?: boolean;
  footer?: boolean;
}

export const StaticLayout: FC<Props> = ({
  backToPageProps,
  children,
  split = true,
  footer = false,
  hasTagline = true,
}) => {
  return (
    <Flex
      minH="100vh"
      flexDir="column"
      flexGrow={1}
      bg="brand.light"
      overflow="hidden"
    >
      <Grid p={3} gridTemplateColumns={'1fr repeat(3, auto) 1fr'}>
        <Box>
          {backToPageProps && (
            <Link {...backToPageProps}>
              <IconButton
                display="flex"
                variant="unstyled"
                icon={<ReactIcon as={MdArrowBack} />}
                aria-label={backToPageProps.ariaLabel}
                color="npGray.700"
              />
            </Link>
          )}
        </Box>
        <Flex alignContent="center" zIndex={20}>
          <Link href="/">
            <Image w={24} alt="Go to home" src="/lockup-horiz.svg" />
          </Link>
        </Flex>
      </Grid>
      <Flex flex="auto" direction="row" overflow="hidden">
        <Stack
          p={3}
          flexDir="column"
          minW={['100%', '100%', '100%', split ? '50%' : '100%']}
          alignItems="center"
        >
          {children}
        </Stack>
        <Flex
          position="absolute"
          right="0"
          top="0"
          zIndex={10}
          width="50%"
          display={['none', 'none', 'none', 'none', 'none', 'block']}
          height="100vh"
          maxH="100vh"
          overflow="hidden"
          backgroundImage="url(/bg-cards.svg)"
          backgroundRepeat="no-repeat"
          backgroundSize="200%"
          backgroundPosition="3vw"
        >
          {hasTagline && (
            <Heading mt={20} ml={16} w="15ch" color="npGray.700">
              Save bookmarks without the unread anxiety
            </Heading>
          )}
        </Flex>
      </Flex>
      {footer && <Footer zIndex={100} />}
    </Flex>
  );
};
