// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { UseDisclosureReturn } from '@chakra-ui/react';
import { createContext } from 'react';

/**
 * Used to manage UI components in /app/** pages.
 */
export interface AppContextProps {
  searchModal: UseDisclosureReturn;
  mainMenuDrawer: UseDisclosureReturn;
  moreDrawer: UseDisclosureReturn;
}

export const AppContext = createContext<AppContextProps>({
  searchModal: {
    isOpen: false,
    onOpen: function (): void {
      throw new Error('Function not implemented.');
    },
    onClose: function (): void {
      throw new Error('Function not implemented.');
    },
    onToggle: function (): void {
      throw new Error('Function not implemented.');
    },
    isControlled: false,
    getButtonProps: function (props?: any) {
      throw new Error('Function not implemented.');
    },
    getDisclosureProps: function (props?: any) {
      throw new Error('Function not implemented.');
    },
  },
  mainMenuDrawer: {
    isOpen: false,
    onOpen: function (): void {
      throw new Error('Function not implemented.');
    },
    onClose: function (): void {
      throw new Error('Function not implemented.');
    },
    onToggle: function (): void {
      throw new Error('Function not implemented.');
    },
    isControlled: false,
    getButtonProps: function (props?: any) {
      throw new Error('Function not implemented.');
    },
    getDisclosureProps: function (props?: any) {
      throw new Error('Function not implemented.');
    },
  },
  moreDrawer: {
    isOpen: false,
    onOpen: function (): void {
      throw new Error('Function not implemented.');
    },
    onClose: function (): void {
      throw new Error('Function not implemented.');
    },
    onToggle: function (): void {
      throw new Error('Function not implemented.');
    },
    isControlled: false,
    getButtonProps: function (props?: any) {
      throw new Error('Function not implemented.');
    },
    getDisclosureProps: function (props?: any) {
      throw new Error('Function not implemented.');
    },
  },
});
