// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { useDisclosure } from '@chakra-ui/react';
import { ActionType } from '~/common/utilities/usage-event/actionType';
import { useUsageEventCreateMutationHelper } from '~/hooks/useUsageEventCreateMutationHelper';

interface Props {
  openAction?: ActionType;
  closeAction?: ActionType;
}

export const useDisclosureWithTracking = (props: Props) => {
  const { logUsageEvent } = useUsageEventCreateMutationHelper();
  const diclosure = useDisclosure();

  return {
    ...diclosure,
    onOpen: () => {
      diclosure.onOpen();
      if (props.openAction) {
        logUsageEvent({ actionType: props.openAction });
      }
    },
    onClose: () => {
      diclosure.onClose();
      if (props.closeAction) {
        logUsageEvent({ actionType: props.closeAction });
      }
    },
  };
};
