// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { localStore } from '~/common/utilities/local-store/localStore';
import {
  LS_USD_BROWSER_SUPPORT_WARNING_DIMISSED_KEY,
  LS_USD_INSTALL_PWA_HINT_DISMISSED_KEY,
} from './localStorageKeys';

/**
 * Removes user session data from localStorage.
 */
const clear = () => {
  for (const storageKey of [
    LS_USD_BROWSER_SUPPORT_WARNING_DIMISSED_KEY,
    LS_USD_INSTALL_PWA_HINT_DISMISSED_KEY,
  ]) {
    localStore.removeItem(storageKey);
  }
};

export const localUserSessionData = {
  clear,
};
