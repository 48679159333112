// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef } from '@chakra-ui/react';

interface Props {}

/**
 * Indicates that this span was added to workaround the error:
 *
 * NotFoundError: Failed to execute 'removeChild' on 'Node':
 * The node to be removed is not a child of this node.
 *
 * Even though you could easily use <span>, it may not be obvious
 * why you're wrapping text with <span></span> for those not faimilar
 * with this error.
 */
export const TranslationProtection = forwardRef<Props, 'span'>((props, ref) => {
  return <span ref={ref} {...props} />;
});
